import {
  PretOkraglyAlu,
  PretKwadrat,
  PretSzescio,
  Plaskownik,
  Rura,
  ProfilZamknietyKwadratowy,
  KatownikiRownoramienne,
  Katowniki,
  Ceowniki,
  Blachy,
  Dwuteownik,
} from "../AluWzory";
import ProfilZamkniety from "../AluWzory/ProfilZamkniety";
import gestoscalu from "../../data/gestoscalu.json";
import gestoscstali from "../../data/gestoscstali.json";
import gestoscnierdzewnej from "../../data/gestoscnierdzewnej.json";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Select, SelectItem } from "@nextui-org/select";
const AluItemDetail = ({
  item,
  activeComponent,
  density,
  setDensity,
  onWeightChange,
}) => {
  const handleChangeDensity = (e) => {
    setDensity(e.target.value);
  };

  return (
    <>
      <h2 className="itemName">{item.name}</h2>
      <div className="topItem">
        <div className="topItem-inner-container">
          <LazyLoadImage
            src={item.render}
            alt={item.name}
            effect="blur"
            className="p-2 h-60 object-cover sm:object-contain sm:p-2"
          />
        </div>
        <div className="topItemRight">
          {item.id === 8 ? (
            ""
          ) : (
            <label className="label" style={{ textTransform: "uppercase" }}>
              {activeComponent === "AluList" ? "" : ""}
              {activeComponent === "stal" ? "" : ""}
              {activeComponent === "StalList" ? "" : ""}
              {activeComponent === "AluList" && (
                <Select
                  name="gestosc"
                  onChange={handleChangeDensity}
                  placeholder="Wybierz gęstość aluminium"
                  aria-label="aluminium"
                  classNames={{
                    base: "bg-transparent",
                    mainWrapper: "bg-transparent",
                    trigger:
                      "bg-transparent min-h-9 h-9 custom-box-select w-1/2 sm:w-full ",
                    value: "text-[#919191]",
                  }}
                >
                  {gestoscalu.map((g) => (
                    <SelectItem key={g.id} value={g.value}>
                      {g.name}
                    </SelectItem>
                  ))}
                </Select>
              )}
              {activeComponent === "stal" && (
                <Select
                  name="gestosc"
                  onChange={handleChangeDensity}
                  placeholder="Wybierz gęstość stali"
                  aria-label="stal"
                  classNames={{
                    base: "bg-transparent",
                    mainWrapper: "bg-transparent",
                    trigger:
                      "bg-transparent min-h-9 h-9 custom-box-select w-1/2 sm:w-full",
                    value: "text-[#919191]",
                  }}
                >
                  {gestoscstali.map((gs) => (
                    <SelectItem key={gs.id} value={gs.value}>
                      {gs.name}
                    </SelectItem>
                  ))}
                </Select>
              )}
              {activeComponent === "StalList" && (
                <Select
                  name="gestosc"
                  onChange={handleChangeDensity}
                  placeholder="Wybierz gęstość stali nierdz."
                  aria-label="stal nierdzewna"
                  classNames={{
                    base: "bg-transparent",
                    mainWrapper: "bg-transparent",
                    trigger:
                      "bg-transparent min-h-9 h-9 custom-box-select w-1/2 sm:w-full",
                    value: "text-[#919191]",
                  }}
                >
                  {gestoscnierdzewnej.map((gn) => (
                    <SelectItem key={gn.id} value={gn.value}>
                      {gn.name}
                    </SelectItem>
                  ))}
                </Select>
              )}
            </label>
          )}
          {item.id === 10 ? (
            <PretOkraglyAlu density={density} onWeightChange={onWeightChange} />
          ) : null}
          {item.id === 9 ? (
            <PretKwadrat density={density} onWeightChange={onWeightChange} />
          ) : null}
          {item.id === 11 ? (
            <PretSzescio density={density} onWeightChange={onWeightChange} />
          ) : null}
          {item.id === 12 ? (
            <Plaskownik density={density} onWeightChange={onWeightChange} />
          ) : null}
          {item.id === 3 ? (
            <Rura density={density} onWeightChange={onWeightChange} />
          ) : null}
          {item.id === 1 ? (
            <ProfilZamknietyKwadratowy
              density={density}
              onWeightChange={onWeightChange}
            />
          ) : null}
          {item.id === 2 ? (
            <ProfilZamkniety
              density={density}
              onWeightChange={onWeightChange}
            />
          ) : null}
          {item.id === 4 ? (
            <KatownikiRownoramienne
              density={density}
              onWeightChange={onWeightChange}
            />
          ) : null}
          {item.id === 5 ? (
            <Katowniki density={density} onWeightChange={onWeightChange} />
          ) : null}
          {item.id === 6 ? (
            <Ceowniki density={density} onWeightChange={onWeightChange} />
          ) : null}
          {item.id === 7 ? (
            <Blachy density={density} onWeightChange={onWeightChange} />
          ) : null}
          {item.id === 8 ? (
            <Dwuteownik onWeightChange={onWeightChange} />
          ) : null}
        </div>
      </div>
    </>
  );
};

export default AluItemDetail;
