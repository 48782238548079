import React, { useState, useEffect, useCallback } from "react";
import { Input } from "@nextui-org/input";
import { onChangeInput } from "../../utils/onChangeInput.ts";
import { onKeyDown } from "../../utils/onKeyDownFunction";
import {
  validateSquareRodLength,
  validateSquareRodQuantity,
  validateSquareRodSideLength,
} from "../../utils/validateEachType";
import messages from "../../errorTypes/Types.ts";

const PretKwadratowy = ({ density, onWeightChange }) => {
  const [sideLength, setSideLength] = useState("");
  const [length, setLength] = useState("");
  const [count, setCount] = useState(1);

  const [errorSideLength, setErrorSideLength] = useState(null);
  const [errorLength, setErrorLength] = useState(null);
  const [errorCount, setErrorCount] = useState(null);

  const calculateWeight = useCallback(() => {
    const volume = Math.pow(Number(sideLength) / 100, 2) * Number(length) * 10; // Obliczam objętość
    const weight = volume * density * count; // Obliczam masę
    const volumeMeter =
      Math.pow(Number(sideLength) / 100, 2) * 10 * density * count; // Waga na metr

    // Przekazanie wyników do komponentu nadrzędnego
    onWeightChange({
      totalWeight: weight.toFixed(3),
      totalWeightPerKg: volumeMeter.toFixed(3),
    });
  }, [sideLength, length, count, density, onWeightChange]);

  useEffect(() => {
    const handler = setTimeout(() => {
      calculateWeight();
    }, 100); // Opóźnienie 100ms

    return () => {
      clearTimeout(handler);
    };
  }, [calculateWeight]);

  return (
    <div className="obliczenia">
      <div className="obliczenia-inputs">
        <label htmlFor="sideLength-input">[A] Wpisz długość boku (mm)</label>
        <Input
          id="sideLength-input"
          type="text"
          autoComplete="off"
          value={sideLength}
          classNames={{
            inputWrapper:
              "bg-transparent rounded-none group-data-[focus=true]:bg-transparent data-[hover=true]:bg-transparent px-0",
            input: "h-9",
          }}
          onChange={(e) =>
            onChangeInput(
              e,
              setSideLength,
              validateSquareRodSideLength,
              5,
              2,
              setErrorSideLength,
              messages.pretyKw.sideLength
            )
          }
          onBlur={() => {
            if (!validateSquareRodSideLength(sideLength)) {
              setErrorSideLength("Niepoprawny format długości [A]");
              return;
            }
          }}
          onKeyDown={(e) => onKeyDown(e, setSideLength)}
        />
        {errorSideLength && (
          <p className="text-error-text">{errorSideLength}</p>
        )}
      </div>
      <div className="obliczenia-inputs">
        <label htmlFor="length-input">[B] Wpisz długość pręta (m)</label>
        <Input
          id="length-input"
          type="text"
          autoComplete="off"
          value={length}
          classNames={{
            inputWrapper:
              "bg-transparent rounded-none group-data-[focus=true]:bg-transparent data-[hover=true]:bg-transparent px-0",
            input: "h-9",
          }}
          onChange={(e) =>
            onChangeInput(
              e,
              setLength,
              validateSquareRodLength,
              2,
              2,
              setErrorLength,
              messages.pretyKw.rodLength
            )
          }
          onBlur={() => {
            if (!validateSquareRodLength(length)) {
              setErrorLength("Niepoprawny format długości [B]");
              return;
            }
          }}
          onKeyDown={(e) => onKeyDown(e, setLength)}
        />
        {errorLength && <p className="text-error-text">{errorLength}</p>}
      </div>
      <div className="obliczenia-inputs">
        <label htmlFor="count-input">Wpisz ilość (szt.)</label>
        <Input
          id="count-input"
          autoComplete="off"
          value={count}
          classNames={{
            inputWrapper:
              "bg-transparent rounded-none group-data-[focus=true]:bg-transparent data-[hover=true]:bg-transparent px-0",
            input: "h-9",
          }}
          onChange={(e) =>
            onChangeInput(
              e,
              setCount,
              validateSquareRodQuantity,
              5,
              0,
              setErrorCount,
              messages.pretyKw.quantity
            )
          }
          onBlur={() => {
            if (!validateSquareRodQuantity(count)) {
              setErrorCount("Niepoprawny format ilości");
              return;
            }
          }}
          onKeyDown={(e) => onKeyDown(e, setCount)}
        />
        {errorCount && <p className="text-error-text">{errorCount}</p>}
      </div>
    </div>
  );
};

export default PretKwadratowy;
