import React, { useState, useEffect, useCallback } from "react";
import { Input } from "@nextui-org/input";
import { onChangeInput } from "../../utils/onChangeInput.ts";
import { onKeyDown } from "../../utils/onKeyDownFunction";
import {
  validatePipeDiameter,
  validatePipeLength,
  validatePipeQuantity,
  validatePipeThickness,
} from "../../utils/validateEachType";
import messages from "../../errorTypes/Types.ts";

const Rura = ({ density, onWeightChange }) => {
  const [diameter, setDiameter] = useState("");
  const [thickness, setThickness] = useState("");
  const [length, setLength] = useState("");
  const [count, setCount] = useState(1);

  const [errorDiameter, setErrorDiameter] = useState(null);
  const [errorThickness, setErrorThickness] = useState(null);
  const [errorLength, setErrorLength] = useState(null);
  const [errorCount, setErrorCount] = useState(null);

  const calculateWeight = useCallback(() => {
    const diameter_m = Number(diameter) / 1000; // mm -> m
    const thickness_m = Number(thickness) / 1000; // mm -> m
    const length_m = Number(length) / 1000; // mm -> m
    const outerRadius = diameter_m / 2; // m
    const innerRadius = outerRadius - thickness_m; // m
    // volume (m^3)
    const volume = Math.PI * (outerRadius ** 2 - innerRadius ** 2) * length_m;
    const density_kg_m3 = density * 1000; // g/cm^3 -> kg/m^3
    const weightPerMeter = volume * density_kg_m3; // mass of 1m in kg
    const totalWeight = weightPerMeter * count; // totalWeight in kg
    onWeightChange({
      totalWeight: totalWeight.toFixed(3),
      totalWeightPerKg: weightPerMeter.toFixed(3),
    });
  }, [diameter, thickness, length, count, density, onWeightChange]);

  useEffect(() => {
    const handler = setTimeout(() => {
      calculateWeight();
    }, 100); // Opóźnienie 100ms

    return () => {
      clearTimeout(handler);
    };
  }, [calculateWeight]);

  return (
    <div className="obliczenia">
      <div className="obliczenia-inputs">
        <label htmlFor="diameter-input">[A] Wpisz średnicę (mm)</label>
        <Input
          id="diameter-input"
          type="text"
          autoComplete="off"
          value={diameter}
          classNames={{
            inputWrapper:
              "bg-transparent rounded-none group-data-[focus=true]:bg-transparent data-[hover=true]:bg-transparent px-0",
            input: "h-9",
          }}
          onChange={(e) => {
            onChangeInput(
              e,
              setDiameter,
              validatePipeDiameter,
              4,
              2,
              setErrorDiameter,
              messages.rura.diameter
            );
          }}
          onBlur={() => {
            if (!validatePipeDiameter(diameter)) {
              setErrorDiameter("Niepoprawny format średnicy");
              return;
            }
          }}
          onKeyDown={(e) => onKeyDown(e, setDiameter)}
        />
        {errorDiameter && <p className="text-error-text">{errorDiameter}</p>}
      </div>
      <div className="obliczenia-inputs">
        <label htmlFor="thickness-input">[B] Wpisz grubość ścianki (mm)</label>
        <Input
          id="thickness-input"
          type="text"
          autoComplete="off"
          value={thickness}
          classNames={{
            inputWrapper:
              "bg-transparent rounded-none group-data-[focus=true]:bg-transparent data-[hover=true]:bg-transparent px-0",
            input: "h-9",
          }}
          onChange={(e) => {
            onChangeInput(
              e,
              setThickness,
              validatePipeThickness,
              2,
              2,
              setErrorThickness,
              messages.rura.thickness
            );
          }}
          onBlur={() => {
            if (!validatePipeThickness(thickness)) {
              setErrorThickness("Niepoprawny format grubości");
              return;
            }
          }}
          onKeyDown={(e) => onKeyDown(e, setThickness)}
        />
        {errorThickness && <p className="text-error-text">{errorThickness}</p>}
      </div>
      <div className="obliczenia-inputs">
        <label htmlFor="length-input">[C] Wpisz długość rury (mm)</label>
        <Input
          id="length-input"
          type="text"
          autoComplete="off"
          value={length}
          classNames={{
            inputWrapper:
              "bg-transparent rounded-none group-data-[focus=true]:bg-transparent data-[hover=true]:bg-transparent px-0",
            input: "h-9",
          }}
          onChange={(e) => {
            onChangeInput(
              e,
              setLength,
              validatePipeLength,
              5,
              2,
              setErrorLength,
              messages.rura.length
            );
          }}
          onBlur={() => {
            if (!validatePipeLength(length)) {
              setErrorLength("Niepoprawny format długości");
              return;
            }
          }}
          onKeyDown={(e) => onKeyDown(e, setLength)}
        />
        {errorLength && <p className="text-error-text">{errorLength}</p>}
      </div>
      <div className="obliczenia-inputs">
        <label htmlFor="count-input">Wpisz ilość (szt.)</label>
        <Input
          id="count-input"
          type="text"
          autoComplete="off"
          value={count}
          classNames={{
            inputWrapper:
              "bg-transparent rounded-none group-data-[focus=true]:bg-transparent data-[hover=true]:bg-transparent px-0",
            input: "h-9",
          }}
          onChange={(e) => {
            onChangeInput(
              e,
              setCount,
              validatePipeQuantity,
              5,
              0,
              setErrorCount,
              messages.rura.quantity
            );
          }}
          onBlur={() => {
            if (!validatePipeQuantity(count)) {
              setErrorCount("Niepoprawny format ilości");
              return;
            }
          }}
          onKeyDown={(e) => onKeyDown(e, setCount)}
        />
        {errorCount && <p className="text-error-text">{errorCount}</p>}
      </div>
    </div>
  );
};

export default Rura;
