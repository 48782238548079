import React, { useState, useEffect, useCallback } from "react";
import { Input } from "@nextui-org/input";
import { onChangeInput } from "../../utils/onChangeInput.ts";
import { onKeyDown } from "../../utils/onKeyDownFunction";
import {
  validateHexagonalRodDiagonal,
  validateHexagonalRodLength,
  validateHexagonalRodQuantity,
} from "../../utils/validateEachType";
import messages from "../../errorTypes/Types.ts";

const PretSzescio = ({ density, onWeightChange }) => {
  const [sideLength, setSideLength] = useState("");
  const [length, setLength] = useState("");
  const [count, setCount] = useState(1);

  const [errorSideLength, setErrorSideLength] = useState(null);
  const [errorLength, setErrorLength] = useState(null);
  const [errorCount, setErrorCount] = useState(null);

  const calculateWeight = useCallback(() => {
    const dbase_m = Number(sideLength) / 1000; // mm -> m
    const height_m = Number(length) / 1000; // mm -> m
    const density_kg_m3 = density * 1000; // kg/m^3
    const volume_hex = (Math.sqrt(3) / 2) * Math.pow(dbase_m, 2) * height_m; // m^3

    // Obliczanie masy pręta
    const weight = volume_hex * density_kg_m3 * count; // kg
    const weightPerMeter = (volume_hex * density_kg_m3 * count) / height_m; // kg/m

    onWeightChange({
      totalWeight: weight.toFixed(3),
      totalWeightPerKg: weightPerMeter.toFixed(3),
    });
  }, [sideLength, length, count, density, onWeightChange]);

  useEffect(() => {
    const handler = setTimeout(() => {
      calculateWeight();
    }, 100); // Opóźnienie 100ms

    return () => {
      clearTimeout(handler);
    };
  }, [calculateWeight]);

  return (
    <div className="obliczenia">
      <div className="obliczenia-inputs">
        <label htmlFor="sideLenght-input">[A] Wpisz przekątną pręta (mm)</label>
        <Input
          id="sideLenght-input"
          type="text"
          autoComplete="off"
          value={sideLength}
          classNames={{
            inputWrapper:
              "bg-transparent rounded-none group-data-[focus=true]:bg-transparent data-[hover=true]:bg-transparent px-0",
            input: "h-9",
          }}
          onChange={(e) => {
            onChangeInput(
              e,
              setSideLength,
              validateHexagonalRodDiagonal,
              4,
              2,
              setErrorSideLength,
              messages.pretySz.diagonal
            );
          }}
          onBlur={() => {
            if (!validateHexagonalRodDiagonal(sideLength)) {
              setErrorSideLength("Niepoprawny format przekątnej");
              return;
            }
          }}
          onKeyDown={(e) => onKeyDown(e, setSideLength)}
        />
        {errorSideLength && (
          <p className="text-error-text">{errorSideLength}</p>
        )}
      </div>
      <div className="obliczenia-inputs">
        <label htmlFor="length-input">[B] Wpisz długość pręta (mm)</label>
        <Input
          id="length-input"
          type="text"
          autoComplete="off"
          value={length}
          classNames={{
            inputWrapper:
              "bg-transparent rounded-none group-data-[focus=true]:bg-transparent data-[hover=true]:bg-transparent px-0",
            input: "h-9",
          }}
          onChange={(e) => {
            onChangeInput(
              e,
              setLength,
              validateHexagonalRodLength,
              5,
              2,
              setErrorLength,
              messages.pretySz.length
            );
          }}
          onBlur={() => {
            if (!validateHexagonalRodLength(length)) {
              setErrorLength("Niepoprawny format długości");
              return;
            }
          }}
          onKeyDown={(e) => onKeyDown(e, setLength)}
        />
        {errorLength && <p className="text-error-text">{errorLength}</p>}
      </div>
      <div className="obliczenia-inputs">
        <label htmlFor="count-input">Wpisz ilość (szt.)</label>
        <Input
          id="count-input"
          type="text"
          autoComplete="off"
          value={count}
          classNames={{
            inputWrapper:
              "bg-transparent rounded-none group-data-[focus=true]:bg-transparent data-[hover=true]:bg-transparent px-0",
            input: "h-9",
          }}
          onChange={(e) => {
            onChangeInput(
              e,
              setCount,
              validateHexagonalRodQuantity,
              5,
              0,
              setErrorCount,
              messages.pretySz.quantity
            );
          }}
          onBlur={() => {
            if (!validateHexagonalRodQuantity(count)) {
              setErrorCount("Niepoprawny format ilości");
              return;
            }
          }}
          onKeyDown={(e) => onKeyDown(e, setCount)}
        />
        {errorCount && <p className="text-error-text">{errorCount}</p>}
      </div>
    </div>
  );
};

export default PretSzescio;
