import React, { useState, useEffect, useCallback } from "react";
import { Input } from "@nextui-org/input";
import { onChangeInput } from "../../utils/onChangeInput.ts";
import {
  validateDoubleTShapeLength,
  validateDoubleTShapeQuantity,
} from "../../utils/validateEachType";
import { onKeyDown } from "../../utils/onKeyDownFunction";
import messages from "../../errorTypes/Types.ts";

const DwuteownikAlu = ({ onWeightChange }) => {
  const [length, setLength] = useState("");
  const [type, setType] = useState("");
  const [data, setData] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [count, setCount] = useState(1);

  const [errorLength, setErrorLength] = useState(null);
  const [errorCount, setErrorCount] = useState(null);

  const fetchData = useCallback(() => {
    let url;
    switch (type) {
      case "I":
        url = "data/dwuteowniki-i.json";
        break;
      case "IPE":
        url = "data/dwuteowniki-ipe.json";
        break;
      case "HE":
        url = "data/dwuteowniki-he.json";
        break;
      default:
        url = "";
    }

    if (url) {
      fetch(url)
        .then((response) => response.json())
        .then((data) => setData(data))
        .catch((error) => console.error(error));
    } else {
      setData([]);
    }
  }, [type]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (selectedOption) {
      const item = data.find((item) => item.Oznaczenie === selectedOption);
      setSelectedItem(item);
    } else {
      setSelectedItem(null);
    }
  }, [selectedOption, data]);

  const calculateWeight = useCallback(() => {
    if (selectedItem && selectedItem.waga * count !== null) {
      onWeightChange({
        totalWeight: (selectedItem.waga * Number(length)).toFixed(3),
        totalWeightPerKg: selectedItem.waga.toFixed(3),
      });
    } else {
      onWeightChange({
        totalWeight: "0.000",
        totalWeightPerKg: "0.000",
      });
    }
  }, [selectedItem, length, count, onWeightChange]);

  useEffect(() => {
    const handler = setTimeout(() => {
      calculateWeight();
    }, 100); // Opóźnienie 100ms

    return () => {
      clearTimeout(handler);
    };
  }, [calculateWeight]);

  return (
    <div className="flex flex-row w-full gap-4 items-start sm:w-full lg:w-full sm:flex sm:flex-col sm:gap-4">
      <div className="flex flex-col w-1/2  items-start sm:w-full lg:w-full sm:flex sm:flex-col">
        <div className="flex flex-col items-start justify-start h-[9em] gap-4 w-full sm:flex sm:flex-col sm:items-start sm:gap-4 sm:h-[9em] sm:w-full lg:flex lg:flex-col lg:items-start lg:justify-start lg:gap-3 lg:h-[9em]">
          <select
            name="dwuteowniki"
            value={type}
            onChange={(e) => setType(e.target.value)}
            className="w-full rounded-md text-[#919191] bg-[#fff] px-4 py-4 sm:w-full sm:rounded-md sm:px-4 sm:py-4 lg:py-3;"
          >
            <option value="">Wybierz typ</option>
            <option value="I">Dwuteowniki IPN</option>
            <option value="IPE">Dwuteowniki IPE</option>
            <option value="HE">Dwuteowniki HE</option>
          </select>

          <select
            value={selectedOption}
            onChange={(e) => setSelectedOption(e.target.value)}
            name="rodzaj"
            className="w-full rounded-md text-[#919191] bg-[#fff] px-4 py-4 sm:w-full sm:rounded-md sm:px-4 sm:py-4 lg:py-3;"
          >
            <option value="">Wybierz rodzaj dwuteownika</option>
            {data.map((item, index) => (
              <option key={index} value={item.Oznaczenie}>
                {item.Oznaczenie}
              </option>
            ))}
          </select>
        </div>
        <div className="obliczenia-inputs">
          <label htmlFor="length-input">[D] Wpisz długość (m)</label>
          <Input
            id="length-input"
            type="text"
            autoComplete="off"
            value={length}
            classNames={{
              inputWrapper:
                "bg-transparent rounded-none group-data-[focus=true]:bg-transparent data-[hover=true]:bg-transparent px-0",
              input: "h-9",
            }}
            onChange={(e) => {
              onChangeInput(
                e,
                setLength,
                validateDoubleTShapeLength,
                2,
                2,
                setErrorLength,
                messages.dwuteowniki.length
              );
            }}
            onBlur={() => {
              if (!validateDoubleTShapeLength(length)) {
                setErrorLength("Niepoprawny format długości");
                return;
              }
            }}
            onKeyDown={(e) => onKeyDown(e, setLength)}
          />
          {errorLength && <p className="text-error-text">{errorLength}</p>}
        </div>
        <div className="obliczenia-inputs">
          <label htmlFor="count-input">Wpisz ilość (szt.)</label>
          <Input
            id="count-input"
            type="text"
            autoComplete="off"
            value={count}
            classNames={{
              inputWrapper:
                "bg-transparent rounded-none group-data-[focus=true]:bg-transparent data-[hover=true]:bg-transparent px-0",
              input: "h-9",
            }}
            onChange={(e) => {
              onChangeInput(
                e,
                setCount,
                validateDoubleTShapeQuantity,
                5,
                0,
                setErrorCount,
                messages.dwuteowniki.quantity
              );
            }}
            onBlur={() => {
              if (!validateDoubleTShapeLength(count)) {
                setErrorCount("Niepoprawny format ilości");
                return;
              }
            }}
            onKeyDown={(e) => onKeyDown(e, setCount)}
          />
          {errorCount && <p className="text-error-text">{errorCount}</p>}
        </div>
      </div>
      {selectedItem && (
        <p>
          Oznaczenie:{" "}
          <span style={{ fontWeight: "600" }}>{selectedItem.Oznaczenie}</span>
          <br />
          Wysokość:{" "}
          <span style={{ fontWeight: "600" }}>{selectedItem.wysokosc} mm</span>
          <br />
          Szerokość:{" "}
          <span style={{ fontWeight: "600" }}>{selectedItem.szerokosc} mm</span>
          <br />
          Grubość:{" "}
          <span style={{ fontWeight: "600" }}>{selectedItem.grubosc} mm</span>
          <br />
          Ścianka:{" "}
          <span style={{ fontWeight: "600" }}>{selectedItem.scianka} mm</span>
          <br />
          Łączenie:{" "}
          <span style={{ fontWeight: "600" }}>{selectedItem.laczenie} mm</span>
          <br />
          Waga:{" "}
          <span style={{ fontWeight: "600" }}>{selectedItem.waga} kg/m</span>
        </p>
      )}
    </div>
  );
};

export default DwuteownikAlu;
