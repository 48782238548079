import React, { useState, useEffect, useCallback } from "react";
import { Input } from "@nextui-org/input";
import { onChangeInput } from "../../utils/onChangeInput.ts";
import { onKeyDown } from "../../utils/onKeyDownFunction";
import {
  validateCShapeWidth,
  validateCShapeHeight,
  validateCShapeThickness,
  validateCShapeLength,
  validateCShapeQuantity,
} from "../../utils/validateEachType";
import messages from "../../errorTypes/Types.ts";

const Ceowniki = ({ density, onWeightChange }) => {
  const [x, setX] = useState("");
  const [y, setY] = useState("");
  const [zBox, setZBox] = useState("");
  const [length, setLength] = useState("");
  const [count, setCount] = useState(1);

  const [errorX, setErrorX] = useState(null);
  const [errorY, setErrorY] = useState(null);
  const [errorZBox, setErrorZBox] = useState(null);
  const [errorLength, setErrorLength] = useState(null);
  const [errorCount, setErrorCount] = useState(null);

  const calculateWeight = useCallback(() => {
    const x_m = Number(x) / 1000; // mm -> m width
    const y_m = Number(y) / 1000; // mm -> m height
    const zBox_m = Number(zBox) / 1000; // mm -> m thickness
    const length_m = Number(length); // m
    const density_kg_m3 = density * 1000; // kg/m^3

    const volume =
      x_m * y_m * length_m - (x_m - 2 * zBox_m) * (y_m - zBox_m) * length_m; // m^3
    const weight = volume * density_kg_m3 * count; // kg
    const weightPerMeter = weight / length_m; // kg/m

    onWeightChange({
      totalWeight: weight.toFixed(3),
      totalWeightPerKg: weightPerMeter.toFixed(3),
    });
  }, [x, y, zBox, length, count, density, onWeightChange]);

  useEffect(() => {
    const handler = setTimeout(() => {
      calculateWeight();
    }, 100); // Opóźnienie 500ms

    return () => {
      clearTimeout(handler);
    };
  }, [x, y, zBox, length, count, calculateWeight]);

  return (
    <div className="obliczenia">
      <div className="obliczenia-inputs">
        <label htmlFor="x-input">[A] Wpisz szerokość (mm)</label>
        <Input
          id="x-input"
          type="text"
          autoComplete="off"
          value={x}
          classNames={{
            inputWrapper:
              "bg-transparent rounded-none group-data-[focus=true]:bg-transparent data-[hover=true]:bg-transparent px-0",
            input: "h-9",
          }}
          onChange={(e) => {
            onChangeInput(
              e,
              setX,
              validateCShapeWidth,
              4,
              2,
              setErrorX,
              messages.ceowniki.width
            );
          }}
          onBlur={() => {
            if (!validateCShapeWidth(x)) {
              setErrorX("Niepoprawny format szerokości");
              return;
            }
          }}
          onKeyDown={(e) => onKeyDown(e, setX)}
        />
        {errorX && <p className="text-error-text">{errorX}</p>}
      </div>
      <div className="obliczenia-inputs">
        <label htmlFor="y-input">[B] Wpisz wysokość (mm)</label>
        <Input
          id="y-input"
          type="text"
          autoComplete="off"
          value={y}
          classNames={{
            inputWrapper:
              "bg-transparent rounded-none group-data-[focus=true]:bg-transparent data-[hover=true]:bg-transparent px-0",
            input: "h-9",
          }}
          onChange={(e) => {
            onChangeInput(
              e,
              setY,
              validateCShapeHeight,
              5,
              2,
              setErrorY,
              messages.ceowniki.height
            );
          }}
          onBlur={() => {
            if (!validateCShapeHeight(y)) {
              setErrorY("Niepoprawny format wysokości");
              return;
            }
          }}
          onKeyDown={(e) => onKeyDown(e, setY)}
        />
        {errorY && <p className="text-error-text">{errorY}</p>}
      </div>
      <div className="obliczenia-inputs">
        <label htmlFor="zBox-input">[C] Wpisz grubość ścianki (mm)</label>
        <Input
          id="zBox-input"
          type="text"
          autoComplete="off"
          value={zBox}
          classNames={{
            inputWrapper:
              "bg-transparent rounded-none group-data-[focus=true]:bg-transparent data-[hover=true]:bg-transparent px-0",
            input: "h-9",
          }}
          onChange={(e) => {
            onChangeInput(
              e,
              setZBox,
              validateCShapeThickness,
              2,
              2,
              setErrorZBox,
              messages.ceowniki.thickness
            );
          }}
          onBlur={() => {
            if (!validateCShapeThickness(zBox)) {
              setErrorZBox("Niepoprawny format grubości ścianki");
              return;
            }
          }}
          onKeyDown={(e) => onKeyDown(e, setZBox)}
        />
        {errorZBox && <p className="text-error-text">{errorZBox}</p>}
      </div>
      <div className="obliczenia-inputs">
        <label htmlFor="length-input">[D] Wpisz długość ceownika (m)</label>
        <Input
          id="length-input"
          type="text"
          autoComplete="off"
          value={length}
          classNames={{
            inputWrapper:
              "bg-transparent rounded-none group-data-[focus=true]:bg-transparent data-[hover=true]:bg-transparent px-0",
            input: "h-9",
          }}
          onChange={(e) => {
            onChangeInput(
              e,
              setLength,
              validateCShapeLength,
              3,
              2,
              setErrorLength,
              messages.ceowniki.length
            );
          }}
          onBlur={() => {
            if (!validateCShapeLength(length)) {
              setErrorLength("Niepoprawny format długości");
              return;
            }
          }}
          onKeyDown={(e) => onKeyDown(e, setLength)}
        />
        {errorLength && <p className="text-error-text">{errorLength}</p>}
      </div>
      <div className="obliczenia-inputs">
        <label htmlFor="count-input">Wpisz ilość (szt.)</label>
        <Input
          id="count-input"
          type="text"
          autoComplete="off"
          value={count}
          classNames={{
            inputWrapper:
              "bg-transparent rounded-none group-data-[focus=true]:bg-transparent data-[hover=true]:bg-transparent px-0",
            input: "h-9",
          }}
          onChange={(e) => {
            onChangeInput(
              e,
              setCount,
              validateCShapeQuantity,
              5,
              0,
              setErrorCount,
              messages.ceowniki.quantity
            );
          }}
          onBlur={() => {
            if (!validateCShapeQuantity(count)) {
              setErrorCount("Niepoprawny format ilości");
              return;
            }
          }}
          onKeyDown={(e) => onKeyDown(e, setCount)}
        />
        {errorCount && <p className="text-error-text">{errorCount}</p>}
      </div>
    </div>
  );
};

export default Ceowniki;
