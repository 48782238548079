import React, { useState, useEffect, useCallback } from "react";
import { Input } from "@nextui-org/input";
import messages from "../../errorTypes/Types.ts";
import { onChangeInput } from "../../utils/onChangeInput.ts";
import { onKeyDown } from "../../utils/onKeyDownFunction.js";
import {
  validateSheetWidth,
  validateSheetThickness,
  validateSheetLength,
  validateSheetQuantity,
} from "../../utils/validateEachType.js";

const Blachy = ({ density, onWeightChange }) => {
  const [plotid, setPlotid] = useState("");
  const [storis, setStoris] = useState("");
  const [ilgis, setIlgis] = useState("");
  const [count, setCount] = useState(1);

  const [errorPlotId, setErrorPlotId] = useState(null);
  const [errorStoris, setErrorStoris] = useState(null);
  const [errorIlgis, setErrorIlgis] = useState(null);
  const [errorCount, setErrorCount] = useState(null);
  const calculateWeight = useCallback(() => {
    const plotId_m = Number(plotid) / 1000; // mm -> m
    const storis_m = Number(storis) / 1000; // mm -> m
    const ilgis_m = Number(ilgis) / 1000; // mm -> m
    const density_kg_m3 = density * 1000; // kg/m^3
    const volume = plotId_m * storis_m * ilgis_m; // m^3
    const weight = volume * density_kg_m3 * count;

    onWeightChange({
      totalBlacha: weight.toFixed(3),
    });
  }, [plotid, storis, ilgis, count, density, onWeightChange]);

  useEffect(() => {
    const handler = setTimeout(() => {
      calculateWeight();
    }, 100); // Opóźnienie 100ms

    return () => {
      clearTimeout(handler);
    };
  }, [calculateWeight]);

  return (
    <div className="obliczenia">
      <div className="obliczenia-inputs">
        <label htmlFor="plotid-input">[A] Wpisz szerokość (mm)</label>
        <Input
          id="plotid-input"
          type="text"
          autoComplete="off"
          value={plotid}
          classNames={{
            inputWrapper:
              "bg-transparent rounded-none group-data-[focus=true]:bg-transparent data-[hover=true]:bg-transparent px-0",
            input: "h-9",
          }}
          onChange={(e) => {
            onChangeInput(
              e,
              setPlotid,
              validateSheetWidth,
              4,
              2,
              setErrorPlotId,
              messages.blachy.width
            );
          }}
          onBlur={() => {
            if (!validateSheetWidth(plotid)) {
              setErrorPlotId("Niepoprawny format szerokości");
              return;
            }
          }}
          onKeyDown={(e) => onKeyDown(e, setPlotid)}
        />
        {errorPlotId && <p className="text-error-text">{errorPlotId}</p>}
      </div>
      <div className="obliczenia-inputs">
        <label htmlFor="storis-input">[B] Wpisz grubość (mm)</label>
        <Input
          id="storis-input"
          type="text"
          autoComplete="off"
          value={storis}
          classNames={{
            inputWrapper:
              "bg-transparent rounded-none group-data-[focus=true]:bg-transparent data-[hover=true]:bg-transparent px-0",
            input: "h-9",
          }}
          onChange={(e) => {
            onChangeInput(
              e,
              setStoris,
              validateSheetThickness,
              2,
              2,
              setErrorStoris,
              messages.blachy.thickness
            );
          }}
          onBlur={() => {
            if (!validateSheetThickness(storis)) {
              setErrorStoris("Niepoprawny format grubości");
              return;
            }
          }}
          onKeyDown={(e) => onKeyDown(e, setStoris)}
        />
        {errorStoris && <p className="text-error-text">{errorStoris}</p>}
      </div>
      <div className="obliczenia-inputs">
        <label htmlFor="ilgis-input">[C] Wpisz długość (mm)</label>
        <Input
          id="ilgis-input"
          type="text"
          autoComplete="off"
          value={ilgis}
          classNames={{
            inputWrapper:
              "bg-transparent rounded-none group-data-[focus=true]:bg-transparent data-[hover=true]:bg-transparent px-0",
            input: "h-9",
          }}
          onChange={(e) => {
            onChangeInput(
              e,
              setIlgis,
              validateSheetLength,
              5,
              2,
              setErrorIlgis,
              messages.blachy.length
            );
          }}
          onBlur={() => {
            if (!validateSheetLength(ilgis)) {
              setErrorIlgis("Niepoprawny format długości");
              return;
            }
          }}
          onKeyDown={(e) => onKeyDown(e, setIlgis)}
        />
        {errorIlgis && <p className="text-error-text">{errorIlgis}</p>}
      </div>
      <div className="obliczenia-inputs">
        <label htmlFor="count-input">Wpisz ilość (szt.)</label>
        <Input
          id="count-input"
          type="text"
          autoComplete="off"
          value={count}
          classNames={{
            inputWrapper:
              "bg-transparent rounded-none group-data-[focus=true]:bg-transparent data-[hover=true]:bg-transparent px-0",
            input: "h-9",
          }}
          onChange={(e) => {
            onChangeInput(
              e,
              setCount,
              validateSheetQuantity,
              6,
              0,
              setErrorCount,
              messages.blachy.quantity
            );
          }}
          onBlur={() => {
            if (!validateSheetQuantity(count)) {
              setErrorCount("Niepoprawny format ilości");
              return;
            }
          }}
          onKeyDown={(e) => onKeyDown(e, setCount)}
        />
        {errorCount && <p className="text-error-text">{errorCount}</p>}
      </div>
    </div>
  );
};

export default Blachy;
