import React, { useState, useEffect, useCallback } from "react";
import { Input } from "@nextui-org/input";
import { onChangeInput } from "../../utils/onChangeInput.ts";
import { onKeyDown } from "../../utils/onKeyDownFunction";
import {
  validateFlatBarLength,
  validateFlatBarQuantity,
  validateFlatBarThickness,
  validateFlatBarWidth,
} from "../../utils/validateEachType";
import messages from "../../errorTypes/Types.ts";

const Plaskownik = ({ density, onWeightChange }) => {
  const [width, setWidth] = useState("");
  const [thickness, setThickness] = useState("");
  const [length, setLength] = useState("");
  const [count, setCount] = useState(1);

  const [errorWidth, setErrorWidth] = useState(null);
  const [errorThickness, setErrorThickness] = useState(null);
  const [errorLength, setErrorLength] = useState(null);
  const [errorCount, setErrorCount] = useState(null);

  const calculateWeight = useCallback(() => {
    const width_m = width / 1000; // mm -> m
    const thickness_m = thickness / 1000; // mm -> m
    const length_m = length / 1000; // mm -> m
    const density_to_m3 = density * 1000; // kg/m^3
    const weight = width_m * thickness_m * length_m * density_to_m3 * count;
    const weightPerMeter = width_m * thickness_m * 1 * density_to_m3 * count;
    onWeightChange({
      totalWeight: weight.toFixed(3),
      totalWeightPerKg: weightPerMeter.toFixed(3),
    });
  }, [width, thickness, length, count, density, onWeightChange]);

  useEffect(() => {
    const handler = setTimeout(() => {
      calculateWeight();
    }, 100); // Opóźnienie 100ms

    return () => {
      clearTimeout(handler);
    };
  }, [calculateWeight]);

  return (
    <div className="obliczenia">
      <div className="obliczenia-inputs">
        <label htmlFor="width-input">[A] Wpisz szerokość (mm)</label>
        <Input
          id="width-input"
          type="text"
          autoComplete="off"
          value={width}
          classNames={{
            inputWrapper:
              "bg-transparent rounded-none group-data-[focus=true]:bg-transparent data-[hover=true]:bg-transparent px-0",
            input: "h-9",
          }}
          onChange={(e) => {
            onChangeInput(
              e,
              setWidth,
              validateFlatBarWidth,
              4,
              2,
              setErrorWidth,
              messages.plaskowniki.width
            );
          }}
          onBlur={() => {
            if (!validateFlatBarWidth(width)) {
              setErrorWidth("Niepoprawny format szerokości");
              return;
            }
          }}
          onKeyDown={(e) => onKeyDown(e, setWidth)}
        />
        {errorWidth && <p className="text-error-text">{errorWidth}</p>}
      </div>
      <div className="obliczenia-inputs">
        <label htmlFor="thickness-input">[B] Wpisz grubość (mm)</label>
        <Input
          id="thickness-input"
          type="text"
          autoComplete="off"
          value={thickness}
          classNames={{
            inputWrapper:
              "bg-transparent rounded-none group-data-[focus=true]:bg-transparent data-[hover=true]:bg-transparent px-0",
            input: "h-9",
          }}
          onChange={(e) => {
            onChangeInput(
              e,
              setThickness,
              validateFlatBarThickness,
              2,
              2,
              setErrorThickness,
              messages.plaskowniki.thickness
            );
          }}
          onBlur={() => {
            if (!validateFlatBarThickness(thickness)) {
              setErrorThickness("Niepoprawny format grubości");
              return;
            }
          }}
          onKeyDown={(e) => onKeyDown(e, setThickness)}
        />
        {errorThickness && <p className="text-error-text">{errorThickness}</p>}
      </div>
      <div className="obliczenia-inputs">
        <label htmlFor="length-input">[C] Wpisz długość (mm)</label>
        <Input
          id="length-input"
          type="text"
          autoComplete="off"
          value={length}
          classNames={{
            inputWrapper:
              "bg-transparent rounded-none group-data-[focus=true]:bg-transparent data-[hover=true]:bg-transparent px-0",
            input: "h-9",
          }}
          onChange={(e) => {
            onChangeInput(
              e,
              setLength,
              validateFlatBarLength,
              5,
              2,
              setErrorLength,
              messages.plaskowniki.length
            );
          }}
          onBlur={() => {
            if (!validateFlatBarLength(length)) {
              setErrorLength("Niepoprawny format długości");
              return;
            }
          }}
          onKeyDown={(e) => onKeyDown(e, setLength)}
        />
        {errorLength && <p className="text-error-text">{errorLength}</p>}
      </div>
      <div className="obliczenia-inputs">
        <label htmlFor="count-input">Wpisz ilość (szt.)</label>
        <Input
          id="count-input"
          type="text"
          autoComplete="off"
          value={count}
          classNames={{
            inputWrapper:
              "bg-transparent rounded-none group-data-[focus=true]:bg-transparent data-[hover=true]:bg-transparent px-0",
            input: "h-9",
          }}
          onChange={(e) => {
            onChangeInput(
              e,
              setCount,
              validateFlatBarQuantity,
              5,
              0,
              setErrorCount,
              messages.plaskowniki.quantity
            );
          }}
          onBlur={() => {
            if (!validateFlatBarQuantity(count)) {
              setErrorCount("Niepoprawny format ilości");
              return;
            }
          }}
          onKeyDown={(e) => onKeyDown(e, setCount)}
        />
        {errorCount && <p className="text-error-text">{errorCount}</p>}
      </div>
    </div>
  );
};

export default Plaskownik;
