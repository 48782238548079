import { removeAtIndexOnDeleteKey } from "./removeCharAtIndex";
export const onKeyDown = (e, setFunction) => {
  if (e.key === "Delete" || e.key === "Backspace") {
    e.preventDefault();
    const { value: str, selectionStart: start, selectionEnd: end } = e.target;

    if (start !== end) {
      setFunction("");
      return;
    }
    const index = e.key === "Backspace" || "Delete" ? start - 1 : start;
    if (index >= 0 && index < str.length) {
      setFunction(removeAtIndexOnDeleteKey(str, index));
    }
  }
};
