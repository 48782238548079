export const onChangeInput = (
  e: React.ChangeEvent<HTMLInputElement>,
  setFunction: (value: string | number) => void,
  validateFunction: (text: string) => boolean,
  maxInteger: number,
  maxDecimal: number,
  setErrorFunction: (error: string | null) => void,
  errorMessages: {
    maxInteger: string;
    maxDecimal: string;
    empty: string;
    invalid: string;
  }
) => {
  e.preventDefault();
  const inputValue = e.target.value;
  const formattedValue = inputValue.replace(",", ".");
  //setFunction(formattedValue);

  const [integerPart, decimalPart] = formattedValue.split(".");

  if (integerPart.length > maxInteger) {
    setErrorFunction(errorMessages.maxInteger);
    return;
  }
  if (decimalPart && decimalPart.length > maxDecimal) {
    setErrorFunction(errorMessages.maxDecimal);
    return;
  }

  if (!inputValue.trim()) {
    setErrorFunction(errorMessages.empty);
    return;
  }
  if (!validateFunction(formattedValue)) {
    setErrorFunction(errorMessages.invalid);
    return;
  }
  setFunction(formattedValue ? parseFloat(formattedValue) : "");
  //setPlotid(integerPart);
  setErrorFunction(null);
};
